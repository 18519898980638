<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="" v-if="serviceName">
    <div class="moving-cleaning-title container">
      {{ translate('moving-heading-title') }}
    </div>
    <div class="moving-cleaning-heading-banner"
         :style="{ 'background-image': 'url(/static/cleaning-header-image.png' }">
      <div class="container">
        <div class="  d-flex justify-content-center">
          <div class="heading-content-container">
            <img src="/static/mr-clean-logo.png" alt="">
            <div class="moving-cleaning-subtitle"> {{ translate('subtitle') }}</div>
            <div class="moving-cleaning-description ">{{ translate('description') }}</div>
            <div class=" d-flex justify-content-center">
              <a href="#service-wrapper">
                <Button :theme="'solid-orange'" :size="'medium'" :text="translate('request-service')"/>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="container our-services-wrapper">
      <div class="our-services-title"> {{ translate('our-services-title') }}</div>
      <div class="moving-cleaning-description"> {{ translate('our-services-description') }}</div>
      <div>
        <CleaningLocationHoursInfo/>
      </div>
      <div class="row ">
        <div class="col-md-4 col-12 ">
          <div class="service-wrapper text-center">
            <div class="d-flex justify-content-center align-items-center icons">
              <SingleCleaningIcon/>
            </div>
            <div class="service-title"> {{ $t('one-time') }}</div>
            <div class="service-description"> {{ translate('highlight-1-description-service') }}</div>
            <div> {{ $t('prices-from') }}:</div>
            <div class="price-amount"> {{ singleCleaningPrice }}{{ $t('price-per-square-meter') }}&sup2;</div>
            <div class=" d-flex justify-content-center">

              <router-link :to="{ name: 'SelectCleaningServicePage' }">
                <Button :theme="'solid-orange'" :size="'medium'" :text="translate('request-service')"/>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 ">
          <div class="service-wrapper text-center" id="service-wrapper">
            <div class="d-flex justify-content-center align-items-center icons">
              <DeepCleaningIcon/>
            </div>
            <div class="service-title"> {{ $t('deep-cleaning') }}</div>
            <div class="service-description"> {{ translate('highlight-2-description-service') }}</div>
            <div> {{ $t('prices-from') }}:</div>
            <div class="price-amount"> {{ deepCleaningPrice }}{{ $t('price-per-square-meter') }}&sup2;</div>
            <div class=" d-flex justify-content-center">
              <router-link :to="{ name: 'SelectCleaningServicePage' }">
                <Button :theme="'solid-orange'" :size="'medium'" :text="translate('request-service')"/>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 ">
          <div class="service-wrapper text-center">
            <div class="d-flex justify-content-center align-items-center icons">
              <SubscriptionCleaningIcon/>
            </div>
            <div class="service-title"> {{ $t('subscription-cleaning') }}</div>
            <div class="service-description"> {{ translate('highlight-3-description-service') }}</div>
            <div> {{ $t('prices-from') }}:</div>
            <div class="price-amount"> {{ subscriptionCleaningPrice }}{{ $t('price-per-square-meter') }}&sup2;</div>
            <div class=" d-flex justify-content-center">
              <router-link :to="{ name: 'SelectCleaningServicePage' }">
                <Button :theme="'solid-orange'" :size="'medium'" :text="translate('request-service')"/>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="process-wrapper">
      <div class="container">
        <div class="process-title"> {{ translate('whats-the-process-title') }}</div>
        <div class="row">
          <div class="col-md-6 process-description"> {{ translate('process-description-left') }}</div>
          <div class="col-md-6 process-description"> {{ translate('process-description-right') }}</div>
        </div>
      </div>

    </div>
    <div class="our-services-wrapper">
      <div class="container">
        <div class="our-services-title">{{ translate('gallery') }}</div>
        <div class="">
          <GalleryMain :images="images[serviceName]"/>
        </div>
      </div>
    </div>
  </div>


  <div>
    <Footer/>
  </div>
</template>
